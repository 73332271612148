<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics

========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <form-wizard
          :subtitle="null"
          :title="null"
          color="rgba(var(--vs-primary), 1)"
          errorColor="rgba(var(--vs-danger), 1)"
          @on-complete="$router.push('/a/study/sets')"
        >
          <tab-content :before-change="validateStep1" class="mb-5" icon="feather icon-upload" title="Upload Terms">
            <!-- tab 1 content -->
            <vx-card class="d-theme-dark-light-bg mt-1">
              <div class="vx-row items-center rounded">
                <div class="vx-col md:w-1/2 w-full p-10">
                  <h1 class="my-3">Upload Terms</h1>
                  <p>
                    Use this template to fill in the details to mass upload terms to Intellectual Point Connect.<br />Use Microsoft Excel or
                    Google Sheets to edit the template
                  </p>
                  <vs-button
                    class="mt-4 font-bold"
                    color="primary"
                    href="https://cdn.discordapp.com/attachments/746242308084858900/879076944711397376/Term_Template.xlsx"
                    icon="icon-download"
                    icon-pack="feather"
                    type="border"
                    >Download Template
                  </vs-button>
                </div>
                <div class="vx-col md:w-1/2 w-full p-10">
                  <upload-file dropTitle="Drop Terms File or" allowedFileTypes=".xlsx" :onSuccess="this.readerData"></upload-file>
                  <h6 v-if="excelData.success === false && excelData.errorMsg" class="mt-2 text-danger font-semibold">
                    {{ excelData.errorMsg }}
                  </h6>
                  <h6 v-if="excelData.success === true && excelData.results" class="mt-2 text-success font-semibold">
                    Uploaded File Contains {{ excelData.results.length }} Valid Terms
                  </h6>
                </div>
              </div>
            </vx-card>
          </tab-content>
          <tab-content :before-change="validateStep2" class="mb-5" icon="feather icon-edit" title="Edit Terms">
            <vs-table
              :data="excelData.results"
              v-if="excelData.results && excelData.results"
              max-items="10"
              pagination
              search
              :key="excelTableKey"
            >
              <template slot="header">
                <h1 class="ml-4">Uploaded Terms</h1>
              </template>

              <template slot="thead">
                <vs-th>Term</vs-th>
                <vs-th>Definition</vs-th>
                <vs-th>Extended Definition</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(attach, index) in data" :key="attach['Term']">
                  <vs-td :data="`${attach['Term']}`">
                    {{ attach['Term'] }}
                  </vs-td>

                  <vs-td :data="`${attach['Definition']}`">
                    {{ attach['Definition'] }}
                  </vs-td>

                  <vs-td :data="`${attach['Extended Definition']}`">
                    {{ attach['Extended Definition'] }}
                  </vs-td>

                  <vs-td>
                    <vs-button
                      color="primary"
                      size="small"
                      type="border"
                      icon-pack="feather"
                      icon="icon-edit"
                      @click="editTermStart(attach, index)"
                    >
                      Edit
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </tab-content>
          <tab-content :before-change="validateStep3" class="mb-5" icon="feather icon-book" title="Assign Sets">
            <vs-table :data="setSearchResults" :sst="true" max-items="5" search @search="setSearch" class="mb-2">
              <template slot="header">
                <h3 class="ml-4">Search Sets</h3>
              </template>

              <template slot="thead">
                <vs-th>Id</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="attach in data" :key="attach.id">
                  <vs-td :data="attach.id">
                    {{ attach.id }}
                  </vs-td>

                  <vs-td :data="`${attach.name}`">
                    <div class="flex align-middle items-center">
                      <img :src="attach.thumbnail" class="rounded mr-3" width="40px" v-if="attach.thumbnail" />
                      {{ `${attach.name}` }}
                    </div>
                  </vs-td>

                  <vs-td>
                    <vs-button color="primary" size="small" type="border" @click="assignSet(attach)"> Assign </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-divider />

            <vs-table :data="setAssignSelected" max-items="5" pagination class="mt-2" :key="setAssignTableKey">
              <template slot="header">
                <h3 class="ml-4 mt-5">Assigned Sets</h3>
              </template>

              <template slot="thead">
                <vs-th>Id</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(attach, index) in data" :key="attach.id">
                  <vs-td :data="attach.id">
                    {{ attach.id }}
                  </vs-td>

                  <vs-td :data="`${attach.name}`">
                    <div class="flex align-middle items-center">
                      <img :src="attach.thumbnail" class="rounded mr-3" width="40px" v-if="attach.thumbnail" />
                      {{ `${attach.name}` }}
                    </div>
                  </vs-td>

                  <vs-td>
                    <vs-button color="primary" size="small" type="border" @click="setAssignSelected.splice(index, 1)"> Remove </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </tab-content>
          <tab-content :before-change="validateStep4" class="mb-5" icon="feather icon-eye" title="Review Details">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <h1>{{ goodToSubmit ? 'Ready To Create Terms, Press Next To Create' : 'Fix Errors Before Re-Submitting' }}</h1>
                <h3 class="mt-2">Total Terms : {{ excelData.results.length }}</h3>
                <h3 class="mt-2">Total Sets : {{ setAssignSelected.length }}</h3>
                <h3 class="mt-2" v-if="goodToSubmitErrors.length !== 0">Total Errors : {{ goodToSubmitErrors.length }}</h3>
                <vs-button class="mt-4" @click="validateStep3" v-if="!goodToSubmit">Re-Check For Errors</vs-button>
              </div>
              <div class="vx-col w-1/2" v-if="goodToSubmitErrors.length !== 0">
                <vs-table :data="goodToSubmitErrors" max-items="5" pagination search>
                  <template slot="header">
                    <h1 class="ml-4">Error List</h1>
                  </template>

                  <template slot="thead">
                    <vs-th>Code</vs-th>
                    <vs-th>Error</vs-th>
                    <vs-th>Item</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr v-for="(attach, index) in data" :key="index">
                      <vs-td :data="`${attach['code']}`">
                        {{ attach['code'] }}
                      </vs-td>

                      <vs-td :data="`${attach['error']}`">
                        {{ attach['error'] }}
                      </vs-td>

                      <vs-td :data="`${attach['item']}`">
                        {{ attach['item'] }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </tab-content>
          <tab-content class="mb-5" icon="feather icon-check" title="Complete">
            <div class="vx-row">
              <div class="vx-col w-1/2 p-2">
                <h5>Total Warnings : {{ submittedWarnings.length }}</h5>
                <vs-table :data="submittedWarnings" v-if="submittedWarnings.length !== 0" max-items="5" pagination search>
                  <template slot="header">
                    <h1 class="ml-4">Warning List</h1>
                  </template>

                  <template slot="thead">
                    <vs-th>Code</vs-th>
                    <vs-th>Warning</vs-th>
                    <vs-th>Item</vs-th>
                    <vs-th>Details</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr v-for="(attach, index) in data" :key="index">
                      <vs-td :data="`${attach['code']}`">
                        {{ attach['code'] }}
                      </vs-td>

                      <vs-td :data="`${attach['warning']}`">
                        {{ attach['warning'] }}
                      </vs-td>

                      <vs-td :data="`${attach['details']}`">
                        {{ attach['details'] }}
                      </vs-td>

                      <vs-td :data="`${attach['item']}`">
                        {{ attach['item'] }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
              <div class="vx-col w-1/2 p-2">
                <h5>Total Warnings : {{ submittedErrors.length }}</h5>
                <vs-table :data="submittedErrors" v-if="submittedErrors.length !== 0" max-items="5" pagination search>
                  <template slot="header">
                    <h1 class="ml-4">Error List</h1>
                  </template>

                  <template slot="thead">
                    <vs-th>Code</vs-th>
                    <vs-th>Error</vs-th>
                    <vs-th>Item</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr v-for="(attach, index) in data" :key="index">
                      <vs-td :data="`${attach['code']}`">
                        {{ attach['code'] }}
                      </vs-td>

                      <vs-td :data="`${attach['error']}`">
                        {{ attach['error'] }}
                      </vs-td>

                      <vs-td :data="`${attach['item']}`">
                        {{ attach['item'] }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
            <div class="vx-col w-full mt-3">
              <h1>Successful Creations : {{ submittedSuccess }}</h1>
              <h1>Upload Count : {{ excelData.results.length }}</h1>
            </div>
          </tab-content>
        </form-wizard>
      </vx-card>
    </div>
    <vs-popup :active.sync="editSingleTermActive" title="Edit Term Values" id="EditTermPopup">
      <vs-input v-model="editSingleTerm.term" class="mb-8 w-full" label-placeholder="Term" />
      <vs-input v-model="editSingleTerm.term_extended" class="mb-8 w-full" label-placeholder="Term Extended" />
      <vs-input v-model="editSingleTerm.term_image" class="mb-6 w-full" label-placeholder="Term Image" />

      <vs-input v-model="editSingleTerm.definition" class="mb-8 w-full" label-placeholder="Definition" />
      <vs-input v-model="editSingleTerm.definition_extended" class="mb-8 w-full" label-placeholder="Definition Extended" />
      <vs-input v-model="editSingleTerm.definition_image" class="mb-6 w-full" label-placeholder="Definition Image" />
      <vs-button color="success" type="filled" @click="editTermSave">Save Changes</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import UploadFile from '@/components/upload/UploadFile';
import * as XLSX from 'xlsx';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  data() {
    return {
      excelTableKey: 0,
      excelData: {
        success: false,
        errorMsg: '',
        header: null,
        results: [],
        meta: null,
      },

      editSingleTermActive: false,
      editSingleTerm: {
        term: '',
        term_extended: '',
        term_image: '',
        definition: '',
        definition_extended: '',
        definition_image: '',
      },

      setSearchResults: [],
      setAssignTableKey: 0,
      setAssignSelected: [],

      setAssignPopup: false,
      setsAssign: [],

      goodToSubmit: false,
      goodToSubmitErrors: [],

      submittedTerms: false,
      submittedWarnings: [],
      submittedErrors: [],
      submittedSuccess: 0,
    };
  },
  methods: {
    assignSet(set) {
      let addSet = true;
      this.setAssignSelected.forEach((item) => {
        if (item.id === set.id) {
          addSet = false;
          return false;
        }
      });
      if (addSet === true) {
        this.setAssignSelected.push(set);
      } else {
        this.$vs.notify({
          title: 'Set Already Assigned',
          text: 'The set you tried to assign has already been assigned',
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle',
        });
      }
    },
    setInit(array) {
      let success = false;
      const parsed = JSON.parse(array);
      parsed.forEach((item) => {
        this.$http.get(`flashcards/sets/${item}`).then((response) => {
          if (response.data) {
            success = true;
            this.setAssignSelected.push(response.data.data);
          }
        });
      });
      if (success) {
        this.$vs.dialog({
          color: 'warning',
          title: 'Set Selected',
          text: 'Please confirm you want to cancel this subscription.',
          accept: this.deleteRecord,
          acceptText: 'Accept',
        });
      }
    },
    setSearch(term) {
      this.$http.get(`flashcards/sets?search=${term}&pageSize=5`).then((response) => {
        if (response.data) {
          this.setSearchResults = response.data;
        }
      });
    },
    editTermSave() {
      this.excelData.results.splice(this.editSingleTerm.index, 1);

      this.excelData.results.unshift({
        'Term': this.editSingleTerm.term,
        'Term Extended': this.editSingleTerm.term_extended,
        'Term Image': this.editSingleTerm.term_image,
        'Definition': this.editSingleTerm.definition,
        'Definition Extended': this.editSingleTerm.definition_extended,
        'Definition Image': this.editSingleTerm.definition_image,
      });

      this.editSingleTermActive = false;
      this.excelTableKey++;
    },
    editTermStart(term, index) {
      this.editSingleTerm = {
        term: term['Term'],
        term_extended: term['Term Extended'],
        term_image: term['Term Image'],
        definition: term['Definition'],
        definition_extended: term['Definition Extended'],
        definition_image: term['Definition Image'],
        index,
      };
      this.editSingleTermActive = true;
    },
    excelError(msg) {
      this.excelData.success = false;
      this.excelData.errorMsg = msg;

      this.$vs.notify({
        title: 'Sheet Parsing Error',
        text: msg,
        color: 'danger',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-alert-circle',
      });
    },
    excelSuccess(msg) {
      this.excelData.success = true;

      this.$vs.notify({
        title: 'File Uploaded Successfully',
        text: msg,
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C = undefined;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = `UNKNOWN ${C}`; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    generateData({ header, results, meta }) {
      if (!header.includes('Term')) {
        return this.excelError('Missing "Term" Column, Please Use The Template File.');
      }

      if (!header.includes('Definition')) {
        return this.excelError('Missing "Definiton" Column, Please Use The Template File.');
      }

      if (!header.includes('Extended Definition')) {
        return this.excelError('Missing "Definition" Column, Please Use The Template File.');
      }

      if (!header.includes('Image')) {
        return this.excelError('Missing "Image" Column, Please Use The Template File.');
      }

      if (results.length <= 0) {
        return this.excelError('File Is Empty, Please Fill Out Values Before Uploading.');
      }

      this.excelSuccess('File Uploaded & Parsed Successfully');
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.meta = meta;
    },
    readerData(rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: firstSheetName };
          this.generateData({ header, results, meta });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        if (this.excelData.success === true) {
          resolve(true);
        } else {
          this.$vs.notify({
            title: 'No File Uploaded',
            text: 'You must upload a file before continuing',
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle',
          });
          reject('correct all values');
        }
      });
    },
    validateStep2() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    validateStep3() {
      return new Promise((resolve) => {
        this.goodToSubmitErrors = [];
        this.setsAssign = [];
        this.goodToSubmit = false;

        this.setAssignSelected.forEach((item) => {
          this.setsAssign.push(item.id);
        });

        const pl = {
          terms: this.excelData.results,
          sets: this.setsAssign,
        };

        this.$vs.loading();

        this.$http
          .post('flashcards/questions/upload/validate', pl)
          .then((response) => {
            if (response.status === 200) {
              this.$vs.loading.close();

              this.goodToSubmit = response.data.data.proceed;
              this.goodToSubmitErrors = response.data.data.errors;
            }
          })
          .catch((exception) => {
            this.$vs.loading.close();
            this.goodToSubmit = false;
            this.goodToSubmitErrors.push({
              code: 'FAILURE',
              error: exception.response.data.error.description,
            });

            return this.$vs.notify({
              title: 'Errors With Submission',
              text: 'Please Check The Log For Details',
              color: 'danger',
              position: 'top-right',
            });
          });

        resolve(true);
      });
    },
    validateStep4() {
      return new Promise((resolve, reject) => {
        if (this.goodToSubmit !== true) {
          this.$vs.notify({
            title: 'Outstanding Errors',
            text: 'Please resolve all errors before submitting',
            color: 'danger',
            position: 'top-right',
          });
          return reject('errors');
        }

        if (this.submittedTerms !== false) {
          this.$vs.notify({
            title: 'Already Submitted Terms',
            text: 'Please refresh the page to start again.',
            color: 'danger',
            position: 'top-right',
          });
          return reject('errors');
        }

        const pl = {
          terms: this.excelData.results,
          sets: this.setsAssign,
        };

        this.$vs.loading();

        this.$http
          .post('flashcards/questions/upload', pl)
          .then((response) => {
            if (response.status === 200) {
              this.$vs.loading.close();

              this.submittedErrors = response.data.data.errors;
              this.submittedWarnings = response.data.data.warnings;
              this.submittedSuccess = response.data.data.success;
            }
          })
          .catch((exception) => {
            this.$vs.loading.close();
            this.goodToSubmit = false;

            this.goodToSubmitErrors.push({
              code: 'FAILURE',
              error: exception.response.data.error.description,
            });

            return this.$vs.notify({
              title: 'Errors With Submission',
              text: 'No Terms Have Been Created',
              color: 'danger',
              position: 'top-right',
            });
          });

        resolve(true);
      });
    },
  },
  mounted() {
    this.setSearch('');
    this.setInit(this.$route.query.sets);
  },
  components: {
    UploadFile,
    FormWizard,
    TabContent,
    XLSX,
    VueJsonPretty,
  },
};
</script>
<style lang="scss">

.theme-dark {
  #EditTermPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
